exports = module.exports = require("../../../node_modules/_css-loader@1.0.1@css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.login-dropdown_login_2HoP0 {\n    padding: .625rem;\n}\n", ""]);

// exports
exports.locals = {
	"login": "login-dropdown_login_2HoP0"
};