const initialState = {
    userInfo: {a: 1}
};
const reducer = function (state, action){
    if (typeof state === 'undefined') state = initialState;
    return state;
};
export {
    reducer as default,
    initialState as userInfoInitialState
};
