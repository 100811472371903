exports = module.exports = require("../../../node_modules/_css-loader@1.0.1@css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".alerts_alerts-inner-container_1KTuF {\n    min-width: 200px;\n    max-width: 548px;\n}\n", ""]);

// exports
exports.locals = {
	"alerts-inner-container": "alerts_alerts-inner-container_1KTuF",
	"alertsInnerContainer": "alerts_alerts-inner-container_1KTuF"
};